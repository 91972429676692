<template>
  <form @submit.prevent="() => {
    v$.$touch()
    handleSubmit(!v$.$invalid)}
    " class="_form">
    <label class="p-field mb-4" :class="{'p-invalid': v$.title.$invalid && v$.title.$dirty}">
      <span class="p-label"> <span v-t="'company_page.company_name'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.title.$model"
        @blur="v$.title.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.email.$invalid && v$.email.$dirty}">
      <span class="p-label"> <span v-t="'email'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.email.$model"
        @blur="v$.email.$touch()"
      />
      <span class="p-hint p-required" v-t="'company_page.attention_for_email'"></span>
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.address.$invalid && v$.address.$dirty}">
      <span class="p-label"> <span v-t="'address'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.address.$model"
        @blur="v$.address.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.name.$invalid && v$.name.$dirty}">
      <span class="p-label"> <span v-t="'company_page.contact_person'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.name.$model"
        @blur="v$.name.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.phone.$invalid && v$.phone.$dirty}">
      <span class="p-label"> <span v-t="'company_page.phone'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.phone.$model"
        @blur="v$.phone.$touch()"
      />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'company_page.credits'"></span>
      <InputText
        type="text"
        v-model="v$.credits.$model"
      />
    </label>
    <label class="p-field mb4">
      <span class="p-label" v-t="'company_page.available_tokens'"></span>
      <InputText
        type="text"
        v-model="v$.tokens.$model"
      />
    </label>
    <Button class="p-button-big _full-width mt-3" type="submit"><span v-t="'company_page.add_company'"></span></Button>
  </form>
</template>

<script>

import { ref } from 'vue';
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

import API from '../../../api'
import { errorHandling } from '../../../utils'

const defaultValues = {
  title: '',
  email: '',
  address: '',
  name: '',
  phone: '',
  credits: '',
  tokens: '',
  is_active: true,
}

export default {
  components: {
    InputText,
    Button
  },
  setup() {
    const toast = useToast()
    const { t } = useI18n()

    const state = ref({...defaultValues});
    const rules = {
      title: { required },
      email: { required, email },
      address: { required },
      name: { required },
      phone: { required },
      credits: {  },
      tokens: {  },
      is_active: {  },
    };
    const v$ = useVuelidate(rules, state)

    const handleSubmit = async (isFormValid) => {
      v$.value.$touch()
      if (!isFormValid) {
        return;
      }
      try {
        await API.adminCompanies('post', 'all', state.value)
        state.value = {...defaultValues}
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 })
        v$.value.$reset()
      } catch (error) {
        const { title, message, fields } = errorHandling(error, t)
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 })
      }
    }
    return {
      v$,
      state,
      handleSubmit,
    }
  }
}
</script>

<style lang="scss" module>
</style>